import React, { FC, ReactNode, useContext, useEffect } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getTelematicsAggregationsBulkEditMode } from '../../selectors/telematicsAggregations.selectors';

import { TELEMATICS_URLS } from '../../telematics.constants';

import { NAMESPACE as telTabsNamespace } from '../../reducer/telematicsTabs.reducer';

import CfPrimaryTab from '../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';
import PageHeader from '../../../shared/components/common/PageHeader/PageHeader';
import PageHeading from '../../../shared/components/common/PageHeading/PageHeading';
import { useLanguageChangeRefresh } from '../../../shared/hooks/useLanguageChangeRefresh';
import UiCacheService from '../../../shared/services/UiCache.service';
import { Thunk } from '../../../types';
import AddRides from '../../components/CreateRidesButton/AddRides';
import DrivesImportHistory from '../../components/DrivesImportHistory/DrivesImportHistory';
import TelematicsExport from '../../components/TelematicsExport/TelematicsExport';
import TosSendButton from '../../components/TosSendButton/TosSendButton';
import { TelematicsContext } from '../Telematics/Telematics';

import { useTelematicsLogger } from './useTelematicsLogger';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { EconomicSystem } from '../../../shared/api/telematics/telematics.types';
import { TelematicsTabsUI } from '../../telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
    flexShrink: 0,
    marginRight: theme.spacing(2),
  },
  actionButton: {
    display: 'flex',
    marginRight: theme.spacing(2),
  },
  header: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  tabsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  fabContainer: {
    marginLeft: 16,
  },
}));

export enum TABS {
  DRIVERS='drivers',
  LOGBOOK='logbook',
  MACHINE='machines'
}

const getSelectedTabIndex = (tab: TABS): number => {
  if (tab === TABS.MACHINE) {
    return 0;
  } else if (tab === TABS.DRIVERS) {
    return 1;
  } else if (tab === TABS.LOGBOOK) {
    return 2;
  }
  return 0;
};
interface Props extends RouteComponentProps<{farmId: string}> {
  bulkEditMode: boolean;
  children: ReactNode,
  init: boolean,
  langId: string,
  setInit: (val: boolean) => void,
  tab: TABS,
}

const TelematicsTabs: FC<Props> = ({
  bulkEditMode, children, history, init, langId, match, setInit, tab,
}) => {
  const classes = useStyles();
  const { economicSystem, economicSystemDate } = useContext(TelematicsContext);

  const hasTosEconomicSystem = (economicSystem === EconomicSystem.TOS) && moment(economicSystemDate).isBefore(moment());

  const selectedTabIndex = getSelectedTabIndex(tab);

  const { farmId } = match.params;
  useTelematicsLogger(tab);
  useLanguageChangeRefresh(langId);

  useEffect(() => {
    const cachedTelematics = UiCacheService.getCachePerSection('telematics');
    const key = telTabsNamespace;
    if (cachedTelematics && key in cachedTelematics) {
      const tabToGo = (cachedTelematics[key] as TelematicsTabsUI)?.lastVisitedTab;
      if (tabToGo) {
        const url = `/farm/${farmId}/telematics/${tabToGo}`;
        history.replace(url);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInit(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDriversTabClick = () => history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}`);
  const handleLogbookTabClick = () => history.push(`/farm/${farmId}/${TELEMATICS_URLS.logbook}`);
  const handleMachinesTabClick = () => history.push(`/farm/${farmId}/${TELEMATICS_URLS.machines}`);

  const handleCreateNewAggregation = () => {
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.drivers}/new`);
  };

  const handleCreateHandworkAggregation = () => {
    history.push(`/farm/${farmId}/${TELEMATICS_URLS.handwork}/new`);
  };

  return (
    <div>
      <div className={classes.container}>
        <PageHeader
          actionButtons={
            <>
              {/* <div className={classes.actionButton}>
                <SendAllButton handleClick={() => {}} tooltipTitle="todo: send all tooltip" />
              </div> */}
              <div className={classes.actionButton}>
                {tab === TABS.DRIVERS && hasTosEconomicSystem && <TosSendButton /> }
                <TelematicsExport selectedTelematicsTab={tab} />
                <DrivesImportHistory langId={langId} />
              </div>
              <div className={classes.fabContainer}>
                <AddRides
                  clickEvents={[
                    handleCreateHandworkAggregation,
                    handleCreateNewAggregation,
                  ]}
                />
              </div>
            </>
          }
          classes={{
            header: classes.header,
          }}
          heading={
            <PageHeading dataTest="telematics-heading" value={<FormattedMessage id="common.telematics" />} />
          }
        />
        <div className={classes.tabsContainer}>
          {init && (
            <CfPrimaryTabs
              centered
              tabValue={selectedTabIndex}
            >
              <CfPrimaryTab
                data-test={'telematics-machines'}
                disabled={bulkEditMode}
                label={<FormattedMessage id="TelematicsTabs.tabs.machines" />}
                onClick={handleMachinesTabClick}
            />
              <CfPrimaryTab
                data-test={'telematics-drivers'}
                label={<FormattedMessage id="TelematicsTabs.tabs.drivers" />}
                onClick={handleDriversTabClick}
                />
              <CfPrimaryTab
                data-test={'telematics-logbook'}
                disabled={bulkEditMode}
                label={<FormattedMessage id="TelematicsTabs.tabs.logbook" />}
                onClick={handleLogbookTabClick}
                />
            </CfPrimaryTabs>
          )}

        </div>
      </div>
      {children}
    </div>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  bulkEditMode: getTelematicsAggregationsBulkEditMode(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsTabs);
