import React, { FC, useContext } from 'react';

import { Stack, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { TELEMATICS_URLS } from '../../telematics.constants';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfTextBadge from '../../../shared/components/misc/CfTextBadge/CfTextBadge';
import { CropName } from '../../../shared/components/specific/CropName/CropName';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import { TelematicsContext } from '../../containers/Telematics/Telematics';
import { getDriveBadgeProps, getDuration } from '../../helpers';
import TelematicsForeignWarning, { TelematicsForeignWarningType } from '../TelematicsForeignWarning/TelematicsForeignWarning';
import TelematicsNotSendInfo from '../TelematicsNotSendInfo/TelematicsNotSendInfo';

import { SeedApplicationType } from '../../../shared/api/agroevidence/agroevidence.types';
import { LogbookAggregatedItemTo, Affiliation, State } from '../../../shared/api/telematics/telematics.types';

const useStyles = makeStyles((theme: Theme) => ({
  operation: {
    color: theme.palette.primary.dark,
  },
  operationNotApproved: {
    color: theme.palette.error.main,
  },
  secondaryTextLight: {
    color: theme.palette.grey[400],
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  firstCell: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
  },
  row: {
    cursor: 'pointer',
  },
  approvals: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 58,
  },
  catchCropIcon: {
    marginRight: 5,
    height: 15,
  },
}));

interface Props {
  data: LogbookAggregatedItemTo;
}

const RideRow: FC<Props> = ({
  data,
}) => {
  const isApprovedRide = data.state === State.APPROVED || data.state === State.THIRD_PARTY_ACKNOWLEDGED;
  const isPostponedRide = data.state === State.DEFERRED;
  const isSentToTos = data.state === State.THIRD_PARTY_ACKNOWLEDGED;
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  // todo zmen vsude v telematice
  const { farmId } = useContext(TelematicsContext);

  const getOperationTooltip = () => {
    if (isPostponedRide) return intl.formatMessage({ id: 'TelematicsList.postponedRide' });
    if (isApprovedRide) return intl.formatMessage({ id: 'TelematicsList.approvedRide' });
    return intl.formatMessage({ id: 'TelematicsList.unApprovedRide' });
  };

  const badgeProps = getDriveBadgeProps(data.type, intl);

  const rowClickRedirectionUrl = `/farm/${farmId}/${TELEMATICS_URLS.machines}/${data.id}`;
  const handleClick = () => history.push(rowClickRedirectionUrl);
  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 1) {
      window.open(rowClickRedirectionUrl, '_blank', 'noopener noreferrer');
    }
  };

  return (
    <TableRow className={classes.row} onClick={handleClick} onMouseDown={handleMouseDown}>
      <CfTableCell name="duration">
        <div className={classes.firstCell}>
          <CfTextBadge
            backgroundColor={badgeProps.backgroundColor}
            color={badgeProps.color}
            fontSize={badgeProps.fontSize}
            text={badgeProps.text}
            tooltipText={badgeProps.tooltip} />
          <span>{getDuration(data.duration)}</span>
        </div>
      </CfTableCell>
      <CfTableCell name="driver.name">
        <div>{data.driver?.name ?? '-'} </div>
        {data.driver?.affiliation === Affiliation.EXTERNAL &&
          <TelematicsForeignWarning
            text={data.driver?.companyName}
            type={TelematicsForeignWarningType.Driver} />
        }
        {data.driver?.affiliation !== Affiliation.EXTERNAL &&
          <div className={classes.secondaryTextLight}>{data.driver?.code ?? '-'}</div>
        }
      </CfTableCell>
      <CfTableCell name="operation">
        <Tooltip title={getOperationTooltip()}>
          <div className={classNames({
            [classes.operation]: isApprovedRide,
            [classes.operationNotApproved]: !isApprovedRide,
            [classes.secondaryTextLight]: isPostponedRide,
          })}>
            {data.operation ?? '-'}
          </div>
        </Tooltip>
        {!isPostponedRide && isApprovedRide && !isSentToTos && <TelematicsNotSendInfo />}
      </CfTableCell>
      <CfTableCell name="productionOperation">
        <span>{data.productionOperation?.name ?? '-' }</span>
      </CfTableCell>
      <CfTableCell name="parcel">
        <div>
          {data.parcel?.blockNumber ?? '-'}
          {data.parcel?.blockNumber && data.parcel?.affiliation !== Affiliation.EXTERNAL && (
          <span>{` ${data.parcel?.localName}` ?? '-'}</span>
          )}
        </div>

        {data.parcel?.affiliation !== Affiliation.EXTERNAL &&
          <Stack alignItems="center" className={classes.secondaryText} direction="row">
            <CropName cropType={data.cropType as SeedApplicationType} sx={{ height: 15 }}>{data.cropName ?? '-'}</CropName>
          </Stack>
        }
        {data.parcel?.affiliation === Affiliation.EXTERNAL &&
          <TelematicsForeignWarning
            text={data.parcel?.subjectName}
            type={TelematicsForeignWarningType.Parcel}
          />}
      </CfTableCell>
      <CfTableCell name="area">
        <div> {data?.cultivated ? <span><CfFormattedNumber decimalDigits={2} value={data.cultivated} /> {' ha'} </span> : '-'}</div>
        {!!data?.parcelArea && <div className={classes.secondaryText}><CfFormattedNumber decimalDigits={2} value={data.parcelArea} /> {' ha'}</div> }
      </CfTableCell>
      <CfTableCell name="distance">
        <span><CfFormattedNumber decimalDigits={2} value={data?.distance || 0} /> {Number(data.distance) >= 0 ? ' km' : ''}</span>
      </CfTableCell>
      <CfTableCell name="additionalEquipment">
        <div>{data.equipment?.name ?? '-'} </div>
        {data.equipment?.code && <div className={classes.secondaryText}>{data.equipment?.code}</div>}
        {data.equipment?.affiliation === Affiliation.EXTERNAL &&
          <TelematicsForeignWarning
            text={data.equipment?.companyName}
            type={TelematicsForeignWarningType.AdditionalEquipment}
          />}
      </CfTableCell>
      <CfTableCell name="approvals">
        <div />
      </CfTableCell>
    </TableRow>
  );
};

export default RideRow;
