import React from 'react';

import { styled } from '@mui/material';

import catchCropSvg from '../../../../assets/img/icons/catch-crop.svg';

import { SeedApplicationType } from '../../../api/agroevidence/agroevidence.types';

type Props = React.PropsWithChildren<{ cropType?: SeedApplicationType } & Pick<React.ComponentProps<typeof Icon>, 'sx'>>;

const CropName = ({ children, cropType, sx }: Props) => (
  <>
    {cropType === SeedApplicationType.CATCH_CROP ? <Icon src={catchCropSvg} sx={sx} /> : undefined}
    {children}
  </>
);

const Icon = styled('img')({
  marginRight: 5,
});

export { CropName };
