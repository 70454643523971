/* eslint-disable @typescript-eslint/no-explicit-any */

import { AnyAction, Action } from 'redux';
import { RSAAAction } from 'redux-api-middleware';
import { delay, call, put, take, takeLatest, Effect, select } from 'redux-saga/effects';

import {
  getTelematicsListTextFilter,
  getTelematicsListDateFrom,
  getTelematicsListDateTo,
  getTelematicsListPage,
  getTelematicsListRowsPerPage,
  getTelematicsListOrderBy,
  getTelematicsListAdvancedFilter,
  getTelematicsListOrder,
  getTelematicsListSelected,
} from '../telematics/selectors/telematicsList.selectors';

import { setFormSubmission, setFormSubmissionOK, setFormSubmissionError } from '../telematics/actions/telematicsDetail.actions';

import * as commonTableTypes from '../shared/actions/table.constants';
import * as driveApiTypes from '../shared/api/telematics/drives/drives.constants';
import * as telDetailTypes from '../telematics/actions/telematicsDetail.constants';
import * as telListTypes from '../telematics/actions/telematicsList.constants';

import { NAMESPACE as TEL_LIST_NAMESPACE } from '../telematics/reducer/telematicsList.reducer';

import { changeDriveStateApi, createDriveApi, editDriveApi, getDrivesApi } from '../shared/api/telematics/drives/drives.api';
import { alignTimesWithDate } from '../telematics/helpers';

import { GetDrivesApiParams } from '../shared/api/telematics/drives/drives.types';
import { State } from '../shared/api/telematics/telematics.types';
import { TelematicsItemFormValues } from '../telematics/telematics.types';
import { GRRT, RT } from './sagas.types';

function* getDrivesApiParams(): Generator<Effect, GetDrivesApiParams, any> {
  const textFilter = yield select(getTelematicsListTextFilter);
  const dateFrom = yield select(getTelematicsListDateFrom);
  const dateTo = yield select(getTelematicsListDateTo);
  const page = yield select(getTelematicsListPage);
  const perPage = yield select(getTelematicsListRowsPerPage);
  const sortCol = yield select(getTelematicsListOrderBy);
  const sortDir = yield select(getTelematicsListOrder);
  const advancedFilter: RT<typeof getTelematicsListAdvancedFilter> = yield select(getTelematicsListAdvancedFilter);

  return {
    dateFrom,
    dateTo,
    search: textFilter !== '' ? textFilter : undefined,
    page: page + 1,
    'per-page': perPage,
    'sort-col': sortCol,
    'sort-dir': sortDir,
    state: advancedFilter.state,
    operation: advancedFilter.operation,
    productionOperation: advancedFilter.productionOperation?.map(pOper => pOper.code),
    parcel: advancedFilter.parcel?.map(parcel => parcel.id),
    affiliation: advancedFilter.parcelAffiliation,
    driver: advancedFilter.driver?.map(driver => driver.code),
    equipment: advancedFilter.equipment?.map(equipment => equipment.code),
    crop: advancedFilter.crop?.map(c => c.code),
    parcelSubjectId: advancedFilter.parcelSubject?.map(parcelSubject => parcelSubject.id),
    gpsUnit: advancedFilter.machine?.map(machine => machine.gpsUnit),
  };
}

const getEditCreateApiPayload = (values: TelematicsItemFormValues, parcelId: string) => {
  if (!values.date || !values.timeFrom || !values.timeTo || !values.operation) {
    return;
  }

  const [timeFrom, timeTo] = alignTimesWithDate(values.date, values.timeFrom, values.timeTo);

  return {
    parcelId: parcelId || undefined,
    timeFrom: timeFrom.toISOString(),
    timeTo: timeTo.toISOString(),
    driver: values.driverCode,
    gpsUnit: values.gpsUnit,
    equipment: values.equipmentCode || undefined,
    workingWidth: values.workingWidth,
    operation: values.operation,
    productionOperation: values.productionOperation?.code,
    cultivated: values.cultivated,
    distance: values.distance,
    bonus: values.bonus,
  };
};

function* fetchDrivesSaga(withDelay = true): Generator<Effect, void, any> {
  // delay to avoid race condition (CFD-936)
  if (withDelay) {
    yield delay(700);
  }
  const params: GRRT<typeof getDrivesApiParams> = yield call(getDrivesApiParams);
  yield put(getDrivesApi(params) as unknown as Action<RSAAAction>);
}

function* createDriveSaga({
  parcelId,
  values,
}: AnyAction): Generator<Effect, void, any> {
  yield put(setFormSubmission(true));
  const params: RT<typeof getEditCreateApiPayload> = yield call(getEditCreateApiPayload, values, parcelId);
  if (!params) return;
  yield put(createDriveApi(params) as unknown as Action<RSAAAction>);
  const { type } = yield take([driveApiTypes.CREATE_DRIVE_SUCCESS, driveApiTypes.CREATE_DRIVE_ERROR]);
  if (type === driveApiTypes.CREATE_DRIVE_SUCCESS) {
    yield put(setFormSubmissionOK());
  } else {
    yield put(setFormSubmissionError());
  }
}

function* editDriveSaga({
  driveId,
  parcelId,
  values,
}: AnyAction): Generator<Effect, void, any> {
  yield put(setFormSubmission(true));
  const params: RT<typeof getEditCreateApiPayload> = yield call(getEditCreateApiPayload, values, parcelId);
  if (!params) return;
  yield put(editDriveApi(driveId, params) as unknown as Action<RSAAAction>);
  const { type } = yield take([driveApiTypes.EDIT_DRIVE_SUCCESS, driveApiTypes.EDIT_DRIVE_ERROR]);
  if (type === driveApiTypes.EDIT_DRIVE_SUCCESS) {
    yield put(setFormSubmissionOK());
  } else {
    yield put(setFormSubmissionError());
  }
}

interface ChangeDriveStateAction extends AnyAction {
    state: State
}
function* changeDriveStateSaga({ state }: ChangeDriveStateAction): Generator<Effect, void, any> {
  const selected = yield select(getTelematicsListSelected);
  yield put(changeDriveStateApi(selected, state) as unknown as Action<RSAAAction>);
  yield take(driveApiTypes.CHANGE_DRIVE_STATE_SUCCESS);
  yield put({ type: commonTableTypes.SET_TABLE_SELECTED, namespace: TEL_LIST_NAMESPACE, selected: [] });
  yield call(fetchDrivesSaga, false);
}

export default function* TelematicsSaga(): Generator<Effect, void, any> {
  yield takeLatest(telListTypes.SAGA_CHANGE_DRIVE_STATE, changeDriveStateSaga);
  yield takeLatest(telListTypes.SAGA_FETCH_DRIVES, fetchDrivesSaga);
  yield takeLatest(telDetailTypes.SAGA_EDIT_DRIVE, editDriveSaga);
  yield takeLatest(telDetailTypes.SAGA_CREATE_DRIVE, createDriveSaga);
}
