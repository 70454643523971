import React, { Fragment } from 'react';

import _ from 'lodash';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';

import { getActions, getIsFetching, getTotalCount, isDeletingActions } from '../../../../../shared/api/agroevidence/actions/actions.selectors';
import { getVariableActionIds } from '../../../../../shared/api/sentinel/variableApplication/variableApplication.selectors';

import ActionLabel from '../../../../../evidence/actions/list/components/ActionLabel/ActionLabel';
import { getRouting } from '../../../../../evidence/actions/shared/misc/action.helpers';
import CfFormattedNumber from '../../../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import FormattedAmount from '../../../../../shared/components/common/FormattedAmount/FormattedAmount';
import { CropName } from '../../../../../shared/components/specific/CropName/CropName';
import CfTableCellNumber from '../../../../../shared/components/tables/CfTableCellNumber/CfTableCellNumber';
import { COLOR_GREY } from '../../../../../theme';

export const getParcelActivitiesPage = state => state.ui.parcelActivities.page;
export const getParcelActivitiesRowsPerPage = state => state.ui.parcelActivities.rowsPerPage;
export const getParcelActivitiesOrder = state => state.ui.parcelActivities.order;
export const getParcelActivitiesOrderBy = state => state.ui.parcelActivities.orderBy;
export const getParcelActivitiesSelected = state => state.ui.parcelActivities.selected;
export const getParcelActivitiesAdvancedFilter = state => state.ui.parcelActivities.advancedFilter;
export const getParcelActivitiesDateFilter = state => state.ui.parcelActivities.dateFilter;

export const getIsFetchingParcelActivities = state => getIsFetching(state) || isDeletingActions(state);
export const getParcelActivitiesTotalCount = state => getTotalCount(state);

export const getProcessedActivities = createSelector(
  getActions,
  getVariableActionIds,
  (actions, variableActionIds) => processValues(actions, variableActionIds || []),
);

export const getSelectedOnPage = createSelector(getActions, getParcelActivitiesSelected, (items, selected) =>
  _.intersection(
    items.map(item => item.id),
    selected,
  ),
);

const getActionMaterial = expense => (expense?.materialName ?? '-');

const getActionMaterialElementValue = (expense, element) =>
  (<CfTableCellNumber>
    {expense && expense[element] ? <CfFormattedNumber value={expense[element]} /> : '-'}
  </CfTableCellNumber>);

const getActionDose = expense => (expense?.amount ? <FormattedAmount expense={expense} param={'amount'} /> : '-');
const getActionDosage = expense => (expense?.dosePerHa ? <FormattedAmount expense={expense} param={'dosePerHa'} perHa={true} /> : '-');

const styles = {
  label: {
    span: {
      minWidth: 'max-content',
      display: 'flex',
    },
  },
};

const processValues = (parcelActions, variableActionIds) =>
  parcelActions.map(activity => {
    const { expenses, parcels } = activity;
    return {
      id: activity.id,
      isDraft: activity.isDraft,
      route: getRouting(activity, variableActionIds),
      single: {
        'dateStart,id': {
          val: <FormattedDate value={activity.date} />,
          rowspan: expenses.length,
        },
        'actionType.localizedNames.value': {
          val: (
            <Fragment>
              <span style={{ fontWeight: 500 }}><FormattedMessage id={`common.${activity.actionType.code}`} /></span>
              {variableActionIds?.includes?.(activity.id) && (
                <span style={styles.label.span}>
                  <ActionLabel />
                </span>
              )}
            </Fragment>
          ),
          rowspan: expenses.length,
        },
        source: { val: <FormattedMessage id={`common.action.source.${activity.source}`} />, rowspan: expenses.length },
        activityCrop: {
          val: (
            <span>
              <CropName type={activity.seedApplicationType}>{activity.crop?.name ?? '-'}</CropName>
            </span>
          ),
          rowspan: expenses.length,
        },
        area: {
          val: (
            <CfTableCellNumber>
              <span>
                <div>
                  <CfFormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={parcels[0].parcelArea - parcels[0].restrictedArea} />
                </div>
                <div style={{ color: COLOR_GREY[500] }}>
                  <CfFormattedNumber
                    maximumFractionDigits={2}
                    minimumFractionDigits={2}
                    value={parcels[0].parcelArea}
                  />
                </div>
              </span>
            </CfTableCellNumber>
          ),
          rowspan: expenses.length,
        },
        material: {
          val: getActionMaterial(expenses[0]),
        },
        dose: {
          val: getActionDose(expenses[0]),
        },
        dosePerHectare: {
          val: getActionDosage(expenses[0]),
        },
        n: {
          val: getActionMaterialElementValue(expenses[0], 'accountableNitrogen'),
        },
        p2o5: {
          val: getActionMaterialElementValue(expenses[0], 'p2o5'),
        },
        k2o: {
          val: getActionMaterialElementValue(expenses[0], 'k2o'),
        },
        mgo: {
          val: getActionMaterialElementValue(expenses[0], 'mgo'),
        },
        cao: {
          val: getActionMaterialElementValue(expenses[0], 'cao'),
        },
        s: {
          val: getActionMaterialElementValue(expenses[0], 's'),
        },
      },
      multi: expenses.slice(1).map(expense => ({
        material: {
          val: getActionMaterial(expense),
        },
        dose: {
          val: getActionDose(expense),
        },
        dosePerHectare: {
          val: getActionDosage(expense),
        },
        n: {
          val: getActionMaterialElementValue(expense, 'accountableN'),
        },
        p2o5: {
          val: getActionMaterialElementValue(expense, 'p2o5'),
        },
        k2o: {
          val: getActionMaterialElementValue(expense, 'k2o'),
        },
        mgo: {
          val: getActionMaterialElementValue(expense, 'mgo'),
        },
        cao: {
          val: getActionMaterialElementValue(expense, 'cao'),
        },
        s: {
          val: getActionMaterialElementValue(expense, 's'),
        },
      })),
    };
  });
