import React, { Fragment, FC, useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Paper, Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { makeStyles } from '@mui/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getParcelsStatistics, getIsFetchingParcelsStatistics } from '../../../../../shared/api/agroevidence/parcels/parcels.selectors';
import { getIsZonesFetching, getZonesStatistics } from '../../../../../shared/api/agroevidence/zones/zones.selectors';

import { getParcelsStatisticsApi, resetParcelsStatisticsApi } from '../../../../../shared/api/agroevidence/parcels/parcels.api';
import { getZonesApi, resetZonesApi } from '../../../../../shared/api/agroevidence/zones/zones.api';
import CfLoader from '../../../../../shared/components/common/CfLoader/CfLoader';
import CfPrimaryTab from '../../../../../shared/components/common/CfPrimaryTab/CfPrimaryTab';
import CfPrimaryTabs from '../../../../../shared/components/common/CfPrimaryTabs/CfPrimaryTabs';
import NameEditDialog from '../../../../../shared/components/common/NameEditDialog/NameEditDialog';
import { Thunk } from '../../../../../types';
import { StatsHeader, StatsFooter, StatsBody, StatsNewZone } from '../../components/ParcelZoneTable/ParcelZoneTable';

import { ParcelsState } from '../../../../../reducers/parcels.reducer.types';
import { FarmStatisticsTo, ZoneDetailTo, ZoneTo } from '../../../../../shared/api/agroevidence/agroevidence.types';
import { ZoneStatistics } from '../../../../../shared/api/agroevidence/zones/zones.types';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  createZone: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[500],
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 15px',
  },
  createZoneForm: {
    padding: '15px 15px',
  },
  container: {
    maxHeight: 400,
  },
}));

const PARCEL_STATS_TAB = 0;
const ZONE_STATS_TAB = 1;

export interface ParcelZoneStatisticsProps {
  getParcelsStatisticsApi: () => void,
  getZonesApi: (search?: string) => void,
  isFetchingParcels: boolean,
  isFetchingZones: boolean,
  onCreateZone: (zone: ZoneTo) => void,
  onDeleteZone: (zone: ZoneTo) => void,
  onFilterZoneInParcels: (zones: ZoneDetailTo[]) => void;
  onUpdateZone: (zone: ZoneTo) => void,
  parcelsStats?: FarmStatisticsTo,
  resetParcelsStatisticsApi: () => void,
  resetZonesApi: () => void,
  shouldReloadData: boolean,
  zonesStats?: ZoneStatistics,
}

export const ParcelZoneStatistics: FC<ParcelZoneStatisticsProps> = ({
  getParcelsStatisticsApi,
  getZonesApi,
  isFetchingParcels,
  isFetchingZones,
  onCreateZone,
  onDeleteZone,
  onFilterZoneInParcels,
  onUpdateZone,
  parcelsStats,
  resetParcelsStatisticsApi,
  resetZonesApi,
  shouldReloadData,
  zonesStats,
}) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState<number>(PARCEL_STATS_TAB);
  const [editZone, setEditZone] = useState(false);
  const [zoneToEdit, setZoneToEdit] = useState<ZoneTo>();
  const [createNewZone, setCreateNewZone] = useState(false);

  useEffect(() =>
    () => {
      resetParcelsStatisticsApi();
      resetZonesApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    if (tabIndex === PARCEL_STATS_TAB) {
      getParcelsStatisticsApi();
    } else if (tabIndex === ZONE_STATS_TAB) {
      getZonesApi();
    }
  }, [getParcelsStatisticsApi, getZonesApi, shouldReloadData, tabIndex]);

  const onCreateZoneConfirm = (zone: ZoneTo) => {
    onCreateZone(zone);
    setCreateNewZone(false);
  };

  const onEditZone = (zone: ZoneTo) => {
    setEditZone(true);
    setZoneToEdit(zone);
  };

  const onEditZoneClose = () => {
    setEditZone(false);
    setZoneToEdit(undefined);
  };

  const onEditZoneAccept = (zone: ZoneTo) => {
    onUpdateZone(zone);
    onEditZoneClose();
  };

  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: number) => {
    setTabIndex(value);
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <CfPrimaryTabs
              centered
              onChange={handleChange}
              tabValue={tabIndex}
              variant="fullWidth"
            >
              <CfPrimaryTab data-test="parcel-list-crops-tab" label={<FormattedMessage id="common.crops" />} />
              <CfPrimaryTab data-test="parcel-list-zones-tab" label={<FormattedMessage id="common.zones" />} />
            </CfPrimaryTabs>
            {(isFetchingParcels || isFetchingZones) ? (
              <CfLoader classes={{ wrapper: classes.wrapper }} />
            ) : (
              <Fragment>
                {tabIndex === PARCEL_STATS_TAB && parcelsStats?.crops && (
                  <Table data-test="crop-list">
                    <StatsHeader />
                    <TableBody>
                      {parcelsStats.crops
                        .sort((a, b) => (b.totalArea) - (a.totalArea))
                        .map((crop, id) => (
                          <StatsBody item={crop} key={id} />
                        ))}
                      {parcelsStats.parcels.totalArea !== undefined && <StatsFooter stats={parcelsStats.parcels} />}
                    </TableBody>
                  </Table>
                )}

                {tabIndex === ZONE_STATS_TAB && (
                  <Fragment>
                    <Table stickyHeader>
                      <StatsHeader />
                    </Table>
                    <Scrollbars autoHeight={true} autoHeightMax={400}>
                      <Table data-test="zone-list">
                        <TableBody>
                          {zonesStats?.items
                            .sort((a, b) => (b.totalArea) - (a.totalArea))
                            .map(item => (
                              <StatsBody
                                item={item}
                                key={item.id}
                                onDeleteZone={onDeleteZone}
                                onEditZone={onEditZone}
                                onFilterZoneInParcels={onFilterZoneInParcels}
                              />
                            ))}
                        </TableBody>
                      </Table>
                    </Scrollbars>
                    <Table>
                      <TableBody>
                        {zonesStats?.totalArea !== undefined &&
                        <StatsFooter stats={zonesStats} />}
                      </TableBody>
                    </Table>
                    <div className={classes.createZone}>
                      {!createNewZone && (
                        <span className={classes.button}>
                          <Button
                            className={classes.label}
                            data-test="add-zone"
                            disabled={createNewZone}
                            endIcon={<AddIcon />}
                            onClick={() => setCreateNewZone(true)}
                          >
                            <FormattedMessage id="ParcelZoneStatistics.create-zone" />
                          </Button>
                        </span>
                      )}
                      {createNewZone && (
                        <div className={classes.createZoneForm}>
                          <StatsNewZone
                            onCreateZoneConfirm={onCreateZoneConfirm}
                            onCreateZoneDismiss={() => setCreateNewZone(false)}
                          />
                        </div>
                      )}
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
      {editZone && (
        <NameEditDialog
          item={zoneToEdit}
          onAccept={onEditZoneAccept}
          onClose={onEditZoneClose}
          opened={editZone}
          title={<FormattedMessage id="ParcelZone.edit" />}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: ParcelsState) => ({
  parcelsStats: getParcelsStatistics(state),
  isFetchingParcels: getIsFetchingParcelsStatistics(state),
  zonesStats: getZonesStatistics(state),
  isFetchingZones: getIsZonesFetching(state),
});

const mapDispatchToProps = (dispatch: Thunk<ParcelsState>) =>
  bindActionCreators(
    {
      getParcelsStatisticsApi,
      getZonesApi,
      resetParcelsStatisticsApi,
      resetZonesApi,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelZoneStatistics);
