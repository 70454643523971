import React, { useState, useMemo } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';

import AddButton from './AddButton';

const useStyles = makeStyles({
  menuPaper: {
    marginLeft: 15,
  },
});

type Props = {
  clickEvents: (() => void)[];
}

const AddRides: React.FC<Props> = ({ clickEvents }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const menuItems = useMemo(() => {
    const handleClick = (handler: () => void) => () => {
      handler();
      setAnchorEl(null);
    };

    return [
      {
        intlId: 'Telematics.addHandwork',
        dataTest: 'telematics-create-handwork-xlsx',
        onClick: handleClick(clickEvents[0]),
        visible: true,
      },
      {
        intlId: 'Telematics.create',
        dataTest: 'telematics-create-xlsx',
        onClick: handleClick(clickEvents[1]),
        visible: true,
      },
    ];
  }, [clickEvents]);

  return (
    <div>
      <AddButton
        onClick={handlePopoverOpen}
        title={<FormattedMessage id="Telematics.addRides" />}
      />
      <Menu
        anchorEl={anchorEl}
        className={classes.menuPaper}
        disableScrollLock={true}
        onClose={handlePopoverClose}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map(({ dataTest, intlId, onClick, visible }, i) => visible && (
          <MenuItem
            data-test={dataTest}
            key={i}
            onClick={onClick}
          >
            <FormattedMessage id={intlId} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AddRides;
