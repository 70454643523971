import React, { Suspense } from 'react';

import { Switch, Route } from 'react-router-dom';

import { LANGUAGE_ID, LANG_KEYS } from '../../../shared/lang/lang.constants';
import { PRECISION_URLS } from '../precision.constants';

import CfLoader from '../../../shared/components/common/CfLoader/CfLoader';
import BaseContainer from '../../../shared/containers/BaseContainer/BaseContainer';
import { Detail as AsAppliedDetail } from '../components/AsApplied/AsAppliedDetail/Detail';
import translationsCZ from '../lang/locale-cs-CZ.json';
import translationsEL from '../lang/locale-el-GR.json';
import translationsEN from '../lang/locale-en-US.json';
import translationsES from '../lang/locale-es-ES.json';
import translationsRO from '../lang/locale-ro-RO.json';
import translationsRU from '../lang/locale-ru-UA.json';
import translationsSR from '../lang/locale-sr-LATN-RS.json';

import PrecisionAsApplied from './PrecisionAsApplied';
import PrecisionDetail from './PrecisionDetail';
import PrecisionFarming from './PrecisionFarming';
import PrecisionServices from './PrecisionServices';
import { PRECISION_LISTING_TABS } from './PrecisionTabs';

const translations = {
  [LANG_KEYS.CZ]: translationsCZ,
  [LANG_KEYS.EN]: translationsEN,
  [LANG_KEYS.SR]: translationsSR,
  [LANG_KEYS.ES]: translationsES,
  [LANG_KEYS.RU]: translationsRU,
  [LANG_KEYS.EL]: translationsEL,
  [LANG_KEYS.RO]: translationsRO,
};

type Props = {
  farmId: string;
  langId: LANGUAGE_ID;
} & Record<'ngRedirectToSowing' | 'ngRedirectToVrf' | 'ngRedirectToVrs', (parcelId: string[]) => void>;

const PrecisionWrapper = (props: Props) => {
  const { langId } = props;

  return (
    <BaseContainer langId={props.langId} translations={translations}>
      <Suspense fallback={<CfLoader />}>
        <Switch>
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.main}`}
            render={routerProps =>
              <PrecisionFarming
                langId={langId}
                tab={PRECISION_LISTING_TABS.SERVICES}
                {...routerProps}>
                <PrecisionServices {...props} {...routerProps} />
              </PrecisionFarming>
          }
          />
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.services()}`}
            render={routerProps =>
              <PrecisionFarming
                langId={langId}
                tab={PRECISION_LISTING_TABS.SERVICES}
                {...routerProps}>
                <PrecisionServices {...props} {...routerProps} />
              </PrecisionFarming>
          }
          />
          <Route
            exact
            path={`/farm/:farmId/${PRECISION_URLS.asApplied()}`}
            render={routerProps =>
              <PrecisionFarming
                langId={langId}
                tab={PRECISION_LISTING_TABS.AS_APPLIED}
                {...routerProps}>
                <PrecisionAsApplied {...props} {...routerProps} />
              </PrecisionFarming>}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.asAppliedDetail()}`}
            render={() => <AsAppliedDetail langId={props.langId} />}
          />
          <Route
            path={`/farm/:farmId/${PRECISION_URLS.servicesDetail()}`}
            render={routerProps => <PrecisionDetail {...props} {...routerProps} />}
          />
        </Switch>
      </Suspense>
    </BaseContainer>
  );
};

export default PrecisionWrapper;
