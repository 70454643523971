import React, { FC, useEffect } from 'react';

import { Box, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FormattedMessage, FormattedDate, FormattedTime, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getItems, getIsFetchingDrives, getTotalCount } from '../../../shared/api/telematics/drives/drives.selectors';
import {
  getTelematicsListAdvancedFilter,
  getTelematicsListOrder,
  getTelematicsListOrderBy,
  getTelematicsListPage,
  getTelematicsListRowsPerPage,
  getTelematicsListTextFilter,
  getTelematicsListDateFilter,
} from '../../selectors/telematicsList.selectors';

import { fetchDrivesSaga } from '../../actions/telematicsList.actions';

import { NAMESPACE as namespace } from '../../reducer/telematicsList.reducer';

import CfFormattedNumber from '../../../shared/components/common/CfFormattedNumber/CfFormattedNumber';
import CfTextBadge from '../../../shared/components/misc/CfTextBadge/CfTextBadge';
import { CropName } from '../../../shared/components/specific/CropName/CropName';
import CfTableBodyEmpty from '../../../shared/components/tables/CfTableBodyEmpty/CfTableBodyEmpty';
import CfTableBodyLoader from '../../../shared/components/tables/CfTableBodyLoader/CfTableBodyLoader';
import CfTableCell from '../../../shared/components/tables/CfTableCell/CfTableCell';
import CfTableWrapper from '../../../shared/components/tables/CfTableWrapper/CfTableWrapper';
// import CfTableCheckbox from '../../../shared/containers/CfTableCheckbox/CfTableCheckbox';
import CfTableFooter from '../../../shared/containers/CfTableFooter/CfTableFooter';
import CfTableHead from '../../../shared/containers/CfTableHead/CfTableHead';
import { Thunk } from '../../../types';
import DoubleLinedHeader from '../../components/DoubleLinedHeader/DoubleLinedHeader';
import TelematicsForeignWarning, { TelematicsForeignWarningType } from '../../components/TelematicsForeignWarning/TelematicsForeignWarning';
import TelematicsNotSendInfo from '../../components/TelematicsNotSendInfo/TelematicsNotSendInfo';
import { getColDesc, getDriveBadgeProps } from '../../helpers';

import { TelematicsState } from '../../../reducers/telematics.reducer.types';
import { SeedApplicationType } from '../../../shared/api/agroevidence/agroevidence.types';
import { Affiliation, LogbookItemTo, State } from '../../../shared/api/telematics/telematics.types';
import { TelematicsAdvancedFilter, TelematicsListDateFilter } from '../../telematics.types';

const columns = {
  from: getColDesc(
    true,
    <DoubleLinedHeader
      leftOffset
      primaryId="common.date"
      secondaryId="common.time"
    />,
  ),
  'driver.name': getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.driverName"
      secondaryId="TelematicsList.chipId"
    />,
  ),
  operation: getColDesc(false, <FormattedMessage id="TelematicsList.operation" />),
  'productionOperation.name': getColDesc(true, <FormattedMessage id="TelematicsList.productionOperation" />),
  parcelName: getColDesc(false,
    <DoubleLinedHeader
      primaryId="TelematicsList.parcelName"
      secondaryId="TelematicsList.crop"
    />,
  ),
  cultivated: getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.cultivatedArea"
      secondaryId="TelematicsList.cultivatedPlot"
    />,
  ),
  traversedDistance: getColDesc(false, <FormattedMessage id="TelematicsList.traversedDistance" />),
  'machine.name': getColDesc(
    true,
    <DoubleLinedHeader
      primaryId="TelematicsList.machine"
      secondaryId="TelematicsList.machineLicencePlate"
    />,
  ),
  additionalEquipment: getColDesc(
    false,
    <DoubleLinedHeader
      primaryId="TelematicsList.additionalEquipment"
      secondaryId="TelematicsList.equipmentChipId"
    />,
  ),
};

const useStyles = makeStyles((theme: Theme) => ({
  cellMultipleRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellRow: {
    margin: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  sensorName: {
    fontWeight: 500,
  },
  secondaryTextLight: {
    color: theme.palette.grey[400],
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  cellWithIcon: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: '0.4rem',
  },
  operation: {
    color: theme.palette.primary.dark,
  },
  operationNotApproved: {
    color: theme.palette.error.main,
  },
  leftOffset: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
}));

export interface TelematicsTableProps {
  advancedFilter: TelematicsAdvancedFilter;
  count: number;
  dateFilter: TelematicsListDateFilter;
  drives: LogbookItemTo[];
  farmId?: string;
  fetchDrives(): void;
  isFetching: boolean;
  langId: string;
  order: string;
  orderBy: string;
  page: number;
  rowsPerPage: number;
  // selected: string[];
// selectedOnPage: string[];
  textFilter: string;
}

export const TelematicsTable: FC<TelematicsTableProps> = ({
  advancedFilter,
  count,
  dateFilter,
  drives,
  farmId,
  fetchDrives,
  isFetching,
  langId,
  order,
  orderBy,
  page,
  // selected,
  // selectedOnPage,
  rowsPerPage,
  textFilter,
}) => {
  useEffect(() => {
    fetchDrives();
  }, [page, rowsPerPage, order, orderBy, textFilter, advancedFilter, dateFilter.lastUpdate, fetchDrives]);

  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const rowClickRedirectionUrl = (driveId: string) => `/farm/${farmId}/telematics/logbook/${driveId}`;
  const handleClick = (driveId: string) => {
    if (driveId) {
      history.push(rowClickRedirectionUrl(driveId));
    }
  };
  const handleMouseDown = (event: React.MouseEvent, driveId: string) => {
    if (event.button === 1) {
      if (driveId) {
        window.open(rowClickRedirectionUrl(driveId), '_blank', 'noopener noreferrer');
      }
    }
  };

  return (
    <CfTableWrapper>
      <CfTableHead
        columns={columns}
        items={drives}
        langId={langId}
        namespace={namespace}
        order={order}
        orderBy={orderBy}
      />
      {isFetching && <CfTableBodyLoader columns={columns} />}
      {!isFetching && drives && drives.length > 0 && (
        <TableBody>
          {drives.map(drive => {
            const isApprovedRide = drive.state !== State.NOT_APPROVED;
            const isPostponedRide = drive.state === State.DEFERRED;

            const getOperationTooltip = () => {
              if (isPostponedRide) return intl.formatMessage({ id: 'TelematicsList.postponedRide' });
              if (isApprovedRide) return intl.formatMessage({ id: 'TelematicsList.approvedRide' });
              return intl.formatMessage({ id: 'TelematicsList.unApprovedRide' });
            };

            const badgeProps = getDriveBadgeProps(drive.type, intl);
            return (
              <TableRow
                className={classes.pointer}
                hover
                key={drive.id}
                onClick={() => handleClick(drive.id)}
                onMouseDown={(e) => handleMouseDown(e, drive.id)}
              >
                {/* {drive.id && <CfTableCheckbox id={drive.id} namespace={namespace} selected={selected} />} */}
                <CfTableCell name={'from'}>
                  <div className={classes.leftOffset}>
                    <CfTextBadge
                      backgroundColor={badgeProps.backgroundColor}
                      color={badgeProps.color}
                      fontSize={badgeProps.fontSize}
                      text={badgeProps.text}
                      tooltipText={badgeProps.tooltip}
                    />
                    <div className={classes.cellMultipleRowsContainer}>
                      <div className={classnames(classes.cellRow, classes.nowrap)}>
                        <FormattedDate value={drive.from} />
                      </div>
                      <div className={classnames(classes.cellRow, classes.secondaryText, classes.nowrap)}>
                        <FormattedTime value={drive.from} /> - <FormattedTime value={drive.to} />
                      </div>
                    </div>
                  </div>
                </CfTableCell>
                <CfTableCell name={'driver.name'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.driver?.name ?? drive.driver?.code ?? '-'}
                      {drive.driver?.affiliation === Affiliation.EXTERNAL &&
                      <TelematicsForeignWarning
                        text={drive.driver?.companyName}
                        type={TelematicsForeignWarningType.Driver}
                      />}
                    </div>
                    {drive.driver?.affiliation === Affiliation.COMPANY &&
                    <div className={classnames(classes.cellRow, classes.secondaryText)}>
                      {drive.driver?.code ?? '-'}
                    </div>}
                  </div>
                </CfTableCell>
                <CfTableCell name={'operation'}>
                  <div className={classes.cellWithIcon}>
                    <Tooltip title={getOperationTooltip()}>
                      <div className={classnames({
                        [classes.operation]: isApprovedRide,
                        [classes.operationNotApproved]: !isApprovedRide,
                        [classes.secondaryText]: isPostponedRide,
                      })}>
                        {drive.operation ?? '-'}
                      </div>
                    </Tooltip>
                    {drive.state !== State.THIRD_PARTY_ACKNOWLEDGED && <TelematicsNotSendInfo />}
                  </div>
                </CfTableCell>
                <CfTableCell name={'productionOperation.name'}>
                  {drive?.productionOperation?.name ?? '-'}
                </CfTableCell>
                <CfTableCell name={'parcelName'}>
                  <div>
                    {drive.parcel?.blockNumber ?? '-'}
                    {drive.parcel?.blockNumber && drive.parcel?.affiliation !== Affiliation.EXTERNAL && (
                    <span>{` ${drive.parcel?.localName}` ?? '-'}</span>
                    )}
                  </div>
                  {drive.parcel?.affiliation !== Affiliation.EXTERNAL &&
                    <Box className={classes.secondaryText} display="flex" sx={{ height: 15 }}>
                      <CropName cropType={drive.cropType as SeedApplicationType}>{drive.cropName ?? '-'}</CropName>
                    </Box>
                  }
                  {drive.parcel?.affiliation === Affiliation.EXTERNAL &&
                    <TelematicsForeignWarning
                      text={drive.parcel?.subjectName}
                      type={TelematicsForeignWarningType.Parcel} />}
                </CfTableCell>
                <CfTableCell name={'cultivated'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.cultivated ? <span><CfFormattedNumber value={drive.cultivated} /> ha</span> : '-'}
                    </div>
                    <div className={classnames(classes.cellRow, classes.secondaryText)}>
                      {drive.parcelArea ? <span><CfFormattedNumber value={drive.parcelArea} /> ha</span> : '-'}
                    </div>
                  </div>
                </CfTableCell>
                <CfTableCell name={'traversedDistance'}>
                  {drive.distance ? <span><CfFormattedNumber value={drive.distance} /> km</span> : '-'}

                </CfTableCell>
                <CfTableCell name={'machine.name'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.machineName ?? drive.gpsUnit ?? '-'}
                    </div>
                    <div className={classnames(classes.cellRow, classes.secondaryText)}>
                      {drive.machineLicencePlate ?? '-'}
                    </div>
                  </div>
                </CfTableCell>
                <CfTableCell name={'additionalEquipment'}>
                  <div className={classes.cellMultipleRowsContainer}>
                    <div className={classes.cellRow}>
                      {drive.equipment?.name ?? drive.equipment?.code ?? '-'}
                    </div>
                    {drive.equipment?.affiliation !== Affiliation.EXTERNAL &&
                      <div className={classnames(classes.cellRow, classes.secondaryText)}>
                        {drive.equipment?.code ?? '-'}
                      </div>}
                    {drive.equipment?.affiliation === Affiliation.EXTERNAL &&
                      <TelematicsForeignWarning
                        text={drive.equipment?.companyName}
                        type={TelematicsForeignWarningType.AdditionalEquipment}
                      />}
                  </div>
                </CfTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      )}
      {!isFetching && !drives.length && <CfTableBodyEmpty colLength={Object.keys(columns).length + 1} />}

      <CfTableFooter
        count={count}
        namespace={namespace}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </CfTableWrapper>
  );
};

const mapStateToProps = (state: TelematicsState) => ({
  page: getTelematicsListPage(state),
  order: getTelematicsListOrder(state),
  orderBy: getTelematicsListOrderBy(state),
  rowsPerPage: getTelematicsListRowsPerPage(state),
  // selected: getTelematicsListSelected(state),
  // selectedOnPage: getTelematicsListSelectedOnPage(state),
  textFilter: getTelematicsListTextFilter(state),
  advancedFilter: getTelematicsListAdvancedFilter(state),
  dateFilter: getTelematicsListDateFilter(state),
  isFetching: getIsFetchingDrives(state),
  count: getTotalCount(state),
  drives: getItems(state),
});

const mapDispatchToProps = (dispatch: Thunk<TelematicsState>) =>
  bindActionCreators(
    {
      fetchDrives: fetchDrivesSaga,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TelematicsTable);
